import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DatabaseUploadMTM from '../components/databaseupload/DatabaseUploadMTM';
import BrowserBanner from "../components/reuseable/BrowserBanner";

const DatabaseUpload = () => (
  <>
  <BrowserBanner />
  <Layout mode={true}>
    <SEO title="Amber by inFeedo | Database Upload" />
    <DatabaseUploadMTM/>
  </Layout></>
)

export default DatabaseUpload;